import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { sendEvent } from 'utils/Event';
import CustomDataGridResult from 'utils/Datagrid/CustomForecastDataGridResult';
import {
  CustomCropToPlantHeader,
  CustomEditBrushAutomaticEffectCell,
  CustomEffectEditCell,
  brushValueList,
  customCropToPlantCell,
  handleRowUpdateValidation,
  themeRotateEffectsFilter,
  themeRotateEffectsPalette,
} from 'utils/Datagrid/CustomRotateMatricesDataGridUtils';
import { inputFloatValidatorRotateEffect } from 'components/generics/Validators/validator';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import {
  checkThreeValueAndDecimalRegexTwoDigitsDatagrid,
  getNewKeyBetweenObjects,
} from 'utils/tools_functions';
import './dataGridRotateEffects.style.scss';
import brushLogo from 'assets/images/brush.svg';
import { toast } from 'react-toastify';
import { updateRotationEffect } from 'services/API/Cooperative';
import {
  Badge,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
} from '@mui/material';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import { validateDatagridMatrix } from 'components/generics/Validators/datagrid.validate';
import Settings from '@mui/icons-material/Tune';
import { StyledBadge } from 'utils/Datagrid/CustomCultureDataGridUtils';
import useFilteredRotationsLocalStorage from 'utils/Datagrid/useFilteredRotationsLocalStorage';
import useDataGridMatricesCellBehaviour from 'utils/Datagrid/useDataGridMatricesCellBehaviour';
import useDataGridMatricesBrushMode from 'utils/Datagrid/useDataGridMatricesBrushMode';
import useDatagridMatricesFilter from 'utils/Datagrid/useDatagridMatricesFilter';

const DataGridRotateEffects = ({ cultures, refreshData, ownerId, from }) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [currentRotationLevel, setCurrentRotationLevel] = useState('cultureN1');
  const [allowSubmitEffect, setAllowSubmitEffect] = useState(false);
  const stackedRequestsRef = useRef([]);
  const previousRotationLevel = useRef('cultureN1');
  const [isLoading, setIsLoading] = useState(false);
  const [errorsEffectFormDefault, setErrorsEffectFormDefault] = useState({
    formError: false,
    effect: {
      message: '',
      validator: [inputFloatValidatorRotateEffect],
    },
    cellErrors: { cultureN1: {}, cultureN2: {} },
  });
  const [rotationLevelToggle, setRotationLevelToggle] = useState({
    cultureN1: false,
    cultureN2: false,
  });
  const [editedRows, setEditedRows] = useState([]);

  const {
    toggleFilter,
    setToggleFilter,
    selectedFilters,
    setSelectedFilters,
    selectAllFilters,
    setSelectAllFilters,
  } = useFilteredRotationsLocalStorage(Number(ownerId), from, cultures, 'rotationEffects');

  const { renderCell, onCellEditStop, rowModesModel, setRowModesModel, handleRowModesModelChange } =
    useDataGridMatricesCellBehaviour({
      stackedRequestsRef,
      errorsEffectFormDefault,
      setErrorsEffectFormDefault,
      currentRotationLevel,
      validateDatagridMatrix,
    });

  const {
    modeBrush,
    setModeBrush,
    allowSubmitEffectBrush,
    setAllowSubmitEffectBrush,
    selectedBrushValue,
    setSelectedBrushValue,
    handleBrushEffect,
    handleClickValue,
  } = useDataGridMatricesBrushMode();

  useEffect(() => {
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  const processedRows = useMemo(() => {
    return cultures?.map((cultureInRow) => {
      let processedRow = {
        id: cultureInRow.id,
        cropToPlant: cultureInRow.name,
        rotationEffects: cultureInRow.rotationEffects,
      };

      return processedRow;
    });
  }, [cultures]);

  useEffect(() => {
    setIsLoading(true);
    const filteredRows = processedRows?.filter((culture) => {
      return selectedFilters?.rows?.[culture.id] !== false;
    });
    const filteredColumns = processedRows?.filter((culture) => {
      return selectedFilters?.columns?.[culture.id] !== false;
    });
    if (
      filteredRows?.length !== processedRows?.length ||
      filteredColumns?.length !== processedRows?.length
    ) {
      processedRows && setRows(filteredRows);
      processedRows && setColumns(filteredColumns);
    } else {
      processedRows && setRows(processedRows);
      processedRows && setColumns(processedRows);
    }
    setIsLoading(false);
  }, [cultures]);

  const renderEditBrushAutomaticEffectCell = (params) => {
    return (
      <CustomEditBrushAutomaticEffectCell
        selectedBrushValue={selectedBrushValue}
        params={params}
        previousRotationLevel={previousRotationLevel}
        currentRotationLevel={currentRotationLevel}
      />
    );
  };

  const renderEditEffectCell = (params) => {
    return (
      <CustomEffectEditCell
        params={params}
        errorsEffectFormDefault={errorsEffectFormDefault}
        currentRotationLevel={currentRotationLevel}
        setRowModesModel={setRowModesModel}
        previousRotationLevel={previousRotationLevel}
      />
    );
  };

  const filteredColumns = processedRows && [...processedRows];

  const processColumns = columns?.map((row) => {
    return {
      field: row.id.toString(),
      headerName: row.cropToPlant,
      headerClassName: 'cropEffect-header',
      editable: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      type: 'number',
      minWidth: 80,
      cellClassName: 'viewModeCell',
      renderCell: renderCell,
      renderEditCell:
        modeBrush && selectedBrushValue !== null
          ? renderEditBrushAutomaticEffectCell
          : renderEditEffectCell,
      valueGetter: (params) => {
        return params.row.rotationEffects.find(
          (rotationEffect) => rotationEffect.cultureId === row.id
        )[currentRotationLevel].effect;
      },
      valueParser: (value) => {
        return checkThreeValueAndDecimalRegexTwoDigitsDatagrid(value);
      },
    };
  });

  const cropToPlantColumn = {
    field: 'cropToPlant',
    headerName: 'Culture à implanter',
    headerClassName: 'cropToPlant-header',
    renderHeader: (params) => CustomCropToPlantHeader({ ...params }),
    renderCell: (params) => customCropToPlantCell({ ...params }),
    cellClassName: 'cropToPlant-cell',
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 140,
  };

  const noColumns = {
    field: 'noColumns',
    headerName: '',
    headerClassName: 'cropEffect-header',
    renderCell: (params) => {
      if (params.row.id === rows[0]?.id) {
        return <div className="noColumns">Aucune colonne à afficher</div>;
      }
      return null;
    },
    cellClassName: 'viewModeCell-noColumns',
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 140,
  };

  const processedColumns = [
    cropToPlantColumn,
    ...(processColumns.length > 0 ? processColumns : [noColumns]),
  ];

  const originalRowsCount = processedRows?.length;
  const countFilteredRows = originalRowsCount - rows.length;

  const countFilteredColumns = originalRowsCount - columns.length.toString();

  const processRowUpdate = useCallback(
    async (updatedRow, previousRow) => {
      let rotationLevel = currentRotationLevel;
      let switchingTab = false;
      if (previousRotationLevel.current !== currentRotationLevel) {
        rotationLevel = previousRotationLevel.current;
        switchingTab = true;
      }
      errorsEffectFormDefault.formError = false;
      const toUpdate = getNewKeyBetweenObjects(previousRow, updatedRow);
      const previousCrop = Number(Object.keys(toUpdate)[0]);
      const isEffectUpdated =
        previousRow.rotationEffects.find(
          (rotationEffect) => rotationEffect.cultureId === previousCrop
        )[rotationLevel].effect !== toUpdate[previousCrop];

      const isEffectSameAsOriginalEffect =
        cultures
          .find((culture) => culture.id === updatedRow.id)
          .rotationEffects.find((rotationEffect) => rotationEffect.cultureId === previousCrop)[
          rotationLevel
        ].effect === toUpdate[previousCrop];

      if (isEffectUpdated) {
        const formatedToUpdateObject = { ...toUpdate };
        Object.defineProperty(
          formatedToUpdateObject,
          'effect',
          Object.getOwnPropertyDescriptor(formatedToUpdateObject, previousCrop)
        );
        delete formatedToUpdateObject[previousCrop];

        setErrorsEffectFormDefault({
          ...validateDatagridMatrix(
            formatedToUpdateObject,
            errorsEffectFormDefault,
            updatedRow.id,
            previousCrop,
            rotationLevel
          ),
        });

        if (switchingTab && errorsEffectFormDefault.formError) {
          setCurrentRotationLevel(previousRotationLevel.current);
          toast.error(
            'Des effets de rotations contiennent des valeurs invalides, corrigez-les avant de changer de tableau'
          );
        }

        updatedRow = {
          ...updatedRow,
          rotationEffects: updatedRow.rotationEffects.map((rotationEffect) => {
            return rotationEffect.cultureId === previousCrop
              ? {
                  ...rotationEffect,
                  [rotationLevel]: {
                    ...rotationEffect[rotationLevel],
                    effect: toUpdate[previousCrop],
                  },
                }
              : rotationEffect;
          }),
        };
        delete updatedRow[previousCrop];

        const updatedEffect = updatedRow.rotationEffects.find(
          (rotationEffect) => rotationEffect.cultureId === previousCrop
        );

        let existInStackedRequest = false;
        stackedRequestsRef.current.forEach((request) => {
          if (
            request?.uriParams?.rotateEffectId === updatedEffect[rotationLevel]?.id &&
            request?.cellInformations?.year === rotationLevel
          ) {
            existInStackedRequest = true;
          }
        });

        await handleRowUpdateValidation(updatedRow, errorsEffectFormDefault);

        if (!existInStackedRequest) {
          stackedRequestsRef.current.push({
            uriParams: {
              ownerId: ownerId,
              yearEffect: rotationLevel === 'cultureN1' ? 'n1' : 'n2',
              rotateEffectId: updatedEffect[rotationLevel]?.id,
            },
            body: {
              id: updatedEffect[rotationLevel]?.id,
              from: updatedEffect[rotationLevel]?.from,
              effect: updatedEffect[rotationLevel]?.effect,
              year: rotationLevel === 'cultureN1' ? 'N1' : 'N2',
            },
            cellInformations: {
              year: rotationLevel,
              rowId: updatedRow.id,
              columnId: previousCrop,
              rotateEffectId: updatedEffect[rotationLevel]?.id,
              effect: updatedEffect[rotationLevel]?.effect,
            },
          });
        } else if (!isEffectSameAsOriginalEffect) {
          stackedRequestsRef.current = stackedRequestsRef.current.map((request) => {
            if (
              request.uriParams.rotateEffectId === updatedEffect[rotationLevel]?.id &&
              request?.cellInformations?.year === rotationLevel
            ) {
              return {
                ...request,
                body: {
                  ...request.body,
                  effect: updatedEffect[rotationLevel]?.effect,
                },
                cellInformations: {
                  ...request.cellInformations,
                  effect: updatedEffect[rotationLevel]?.effect,
                },
              };
            } else {
              return request;
            }
          });
        } else {
          let tempStackedRequests = [];
          stackedRequestsRef.current.forEach((request) => {
            if (
              request.cellInformations.rotateEffectId !== updatedEffect[rotationLevel]?.id ||
              request.cellInformations.year !== rotationLevel
            ) {
              tempStackedRequests.push(request);
            }
          });
          stackedRequestsRef.current = tempStackedRequests;
        }

        setAllowSubmitEffect(stackedRequestsRef?.current?.length);
        if (modeBrush) {
          setAllowSubmitEffectBrush(true);
        }

        setEditedRows((prev) => ({
          ...prev,
          [updatedRow.id]: updatedRow,
        }));

        return updatedRow;
      }

      return previousRow;
    },
    [cultures, currentRotationLevel, stackedRequestsRef, rows, editedRows]
  );

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const handleRotationLevel = (level) => {
    previousRotationLevel.current = level === 'cultureN1' ? 'cultureN2' : 'cultureN1';
    setCurrentRotationLevel(level);
  };

  useEffect(() => {
    setRotationLevelToggle((prev) => ({
      ...prev,
      cultureN1: Object.keys(errorsEffectFormDefault?.cellErrors.cultureN1 || {}).length === 0,
    }));
    setRotationLevelToggle((prev) => ({
      ...prev,
      cultureN2: Object.keys(errorsEffectFormDefault?.cellErrors.cultureN2 || {}).length === 0,
    }));
  }, [errorsEffectFormDefault]);

  const callAPI = async () => {
    setIsLoading(true);
    if (
      Object.keys(errorsEffectFormDefault?.cellErrors?.cultureN1)?.length !== 0 ||
      Object.keys(errorsEffectFormDefault?.cellErrors?.cultureN2)?.length !== 0
    ) {
      toast.warning(
        "Des effets de rotations contiennent des valeurs invalides, ils n'ont pas pas été enregistrés."
      );
    }

    const requestBody = stackedRequestsRef.current.map((request) => request.body);

    try {
      await updateRotationEffect(from, ownerId, requestBody);
      setAllowSubmitEffect(false);
      setAllowSubmitEffectBrush(false);
      setModeBrush(false);
      setSelectedBrushValue(null);
      setEditedRows([]);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          "Une erreur est survenue : impossible de mettre à jour l'effet de rotation."
      );
    }
    stackedRequestsRef.current = [];
    refreshData();

    toast.success('Les effets de rotations ont été mis à jour.');
  };

  useEffect(() => {
    if (processedRows && !isLoading) {
      const filteredRows = processedRows?.filter((row) => selectedFilters?.rows?.[row.id]);
      const columnsToFilter = filteredColumns?.filter(
        (column) => selectedFilters?.columns?.[column.id]
      );
      const updatedFilteredRows = filteredRows.map((row) => {
        if (editedRows[row.id]) {
          return { ...row, ...editedRows[row.id] };
        }
        return row;
      });
      setRows(updatedFilteredRows || []);
      setColumns(columnsToFilter || []);
    }
  }, [selectedFilters, editedRows]);

  const { handleOnChangeFilter, renderFilterMenuItems, handleToggleFilter, isIndeterminate } =
    useDatagridMatricesFilter({
      selectedFilters,
      setSelectedFilters,
      selectAllFilters,
      setSelectAllFilters,
      setToggleFilter,
    });

  const filterMenuItems = cultures?.map((culture) =>
    renderFilterMenuItems(culture, selectedFilters?.rows, 'rows')
  );
  const filterMenuColumnsItems = cultures?.map((culture) =>
    renderFilterMenuItems(culture, selectedFilters?.columns, 'columns')
  );

  return (
    cultures && (
      <div className="section">
        <div className="main_container">
          <div className="override">
            <h1 className="title_section title_section_culture title_rotate_effects">
              Matrice de rotation
            </h1>
          </div>
          <div
            className={`datagrid_container rotate_effects_container ${
              modeBrush ? 'modeBrush' : ''
            }`}
          >
            <div className="contain_tab_rotation">
              <div
                className={`${currentRotationLevel === 'cultureN1' ? 'active' : ''}
                ${rotationLevelToggle.cultureN2 ? '' : 'disabled' ? 'disabled' : ''}
                `}
                onClick={() => {
                  if (currentRotationLevel !== 'cultureN1') {
                    if (rotationLevelToggle.cultureN2) {
                      handleRotationLevel('cultureN1');
                    } else {
                      toast.warning(
                        'Des effets de rotations N-2 contiennent des valeurs invalides.'
                      );
                    }
                  }
                }}
              >
                EFFETS DE ROTATION N-1
              </div>
              <div
                className={`${currentRotationLevel === 'cultureN2' ? 'active' : ''}
                ${rotationLevelToggle.cultureN1 ? '' : 'disabled' ? 'disabled' : ''}
                `}
                onClick={() => {
                  if (currentRotationLevel !== 'cultureN2') {
                    if (rotationLevelToggle.cultureN1) {
                      handleRotationLevel('cultureN2');
                    } else {
                      toast.warning(
                        'Des effets de rotations N-1 contiennent des valeurs invalides.'
                      );
                    }
                  }
                }}
              >
                EFFETS DE ROTATION N-2
              </div>
              <ThemeProvider theme={customButtonTheme}>
                <Button disabled={!allowSubmitEffect} onClick={callAPI}>
                  Enregistrer les modifications
                </Button>
                <div className="contain_brush">
                  <Button disabled={!allowSubmitEffectBrush} onClick={handleBrushEffect}>
                    Terminer les modifications
                    <span>
                      <img src={brushLogo} alt="Logo pinceau" className="brush_logo" />
                    </span>
                  </Button>
                </div>
              </ThemeProvider>
            </div>
            <div className={`${fullScreen ? 'fullscreen' : ''}`}>
              <div
                className={`${
                  fullScreen && toggleFilter
                    ? 'contain_brush_effect_fullscreen contain_brush_effect'
                    : 'contain_brush_effect'
                }`}
              >
                <ThemeProvider theme={themeRotateEffectsFilter}>
                  <div className="filter_container_rotate_effects">
                    <div className="button_filters_effects" onClick={handleToggleFilter}>
                      <span>{toggleFilter ? '' : 'Afficher les filtres'}</span>
                      <Settings className="settings-icon" />
                    </div>
                    {toggleFilter && (
                      <>
                        <div>
                          <FormControl className="formControl">
                            <InputLabel shrink={false} id="filterLabel" className="input-label">
                              Filtrer les lignes
                            </InputLabel>
                            <Select
                              labelId="filterLabel"
                              id="filter"
                              value={Object.keys(selectedFilters.rows).filter(
                                (id) => selectedFilters?.rows[id]
                              )}
                              onChange={(event) =>
                                handleOnChangeFilter(event.target.value, 'rows', cultures)
                              }
                              multiple
                              renderValue={() => ''}
                              MenuProps={{ autoFocus: false }}
                            >
                              <MenuItem value="all">
                                <Checkbox
                                  indeterminate={isIndeterminate('rows')}
                                  checked={selectAllFilters?.rows}
                                />
                                <ListItemText primary="Tout sélectionner" />
                              </MenuItem>
                              {filterMenuItems}
                            </Select>
                            <StyledBadge
                              badgeContent={countFilteredRows}
                              color="primary"
                            ></StyledBadge>
                          </FormControl>
                        </div>
                        <div>
                          <FormControl className="formControl">
                            <InputLabel shrink={false} id="filterLabel" className="input-label">
                              Filtrer les colonnes
                            </InputLabel>
                            <Select
                              labelId="filterLabel"
                              id="filter"
                              value={Object.keys(selectedFilters.columns).filter(
                                (id) => selectedFilters?.columns[id]
                              )}
                              onChange={(event) =>
                                handleOnChangeFilter(event.target.value, 'columns', cultures)
                              }
                              multiple
                              renderValue={() => ''}
                              MenuProps={{ autoFocus: false }}
                            >
                              <MenuItem value="all">
                                <Checkbox
                                  indeterminate={isIndeterminate('columns')}
                                  checked={selectAllFilters?.columns}
                                />
                                <ListItemText primary="Tout sélectionner" />
                              </MenuItem>
                              {filterMenuColumnsItems}
                            </Select>
                            <StyledBadge
                              badgeContent={countFilteredColumns}
                              color="primary"
                            ></StyledBadge>
                          </FormControl>
                        </div>
                      </>
                    )}
                  </div>
                </ThemeProvider>
                <div
                  className={`${
                    fullScreen ? (toggleFilter ? 'fullscreen-div' : '') : 'not-fullscreen'
                  }`}
                ></div>
                <div>
                  <ThemeProvider theme={themeRotateEffectsPalette}>
                    <div className="list_effect">
                      {brushValueList &&
                        brushValueList?.map((brushValue, index) => {
                          return (
                            <Badge
                              key={brushValue.number}
                              badgeContent={'✓'}
                              color="primary"
                              invisible={selectedBrushValue !== brushValue.number.toString()}
                            >
                              <span
                                className={`colorSpan ${
                                  selectedBrushValue === brushValue.number
                                    ? 'selectedBrushValue'
                                    : ''
                                }`}
                                onClick={handleClickValue}
                                style={{
                                  backgroundColor: `color-mix(in srgb, ${brushValue.color} 100%, white)`,
                                  color: index >= 3 && index < 6 ? 'black' : 'white',
                                  cursor: modeBrush ? '' : 'pointer',
                                }}
                              >
                                {brushValue.number}
                              </span>
                            </Badge>
                          );
                        })}
                    </div>
                  </ThemeProvider>
                </div>

                <div className="contain_switch_effect">
                  {fullScreen && (
                    <div
                      className={`contain_brush ${toggleFilter ? 'contain_brush_fullscreen' : ''}`}
                    >
                      <ThemeProvider theme={customButtonTheme}>
                        <Button disabled={!allowSubmitEffectBrush} onClick={handleBrushEffect}>
                          Terminer les modifications
                          <span>
                            <img src={brushLogo} alt="Logo pinceau" className="brush_logo" />
                          </span>
                        </Button>
                      </ThemeProvider>
                    </div>
                  )}
                  {fullScreen ? (
                    <ZoomInMapIcon onClick={handleFullScreen} />
                  ) : (
                    <ZoomOutMapIcon onClick={handleFullScreen} />
                  )}
                </div>
              </div>
              <CustomDataGridResult
                rows={rows}
                fromRotateEffects
                columns={processedColumns}
                disableColumnResize={true}
                processRowUpdate={processRowUpdate}
                onCellEditStop={onCellEditStop}
                onRowModesModelChange={handleRowModesModelChange}
                rowModesModel={rowModesModel}
                setRowModesModel={setRowModesModel}
                fullSize={true}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DataGridRotateEffects;
