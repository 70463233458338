//REACT
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';

// YARN
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
} from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro';
import Settings from '@mui/icons-material/Tune';

// SERVICES
import { sendEvent } from 'utils/Event';
import CustomDataGridResult from 'utils/Datagrid/CustomForecastDataGridResult';
import {
  CustomCropToPlantHeader,
  CustomEditBrushAutomaticEffectCell,
  CustomEffectEditCell,
  brushValueList,
  customCropToPlantCell,
  handleRowUpdateValidation,
  themeRotateEffectsFilter,
  themeRotateEffectsPalette,
} from 'utils/Datagrid/CustomRotateMatricesDataGridUtils';
import { inputFloatValidatorRotateEffect } from 'components/generics/Validators/validator';
import {
  checkThreeValueAndDecimalRegexTwoDigitsDatagrid,
  getNewKeyBetweenObjects,
} from 'utils/tools_functions';
import { updateGroundTypeEffect } from 'services/API/Cooperative';
import { StyledBadge } from 'utils/Datagrid/CustomCultureDataGridUtils';
import { validateDatagridMatrix } from 'components/generics/Validators/datagrid.validate';
import useFilteredRotationsLocalStorage from 'utils/Datagrid/useFilteredRotationsLocalStorage';
import useDatagridMatricesFilter from '../../../utils/Datagrid/useDatagridMatricesFilter';
import useDataGridMatricesCellBehaviour from 'utils/Datagrid/useDataGridMatricesCellBehaviour';
import useDataGridMatricesBrushMode from 'utils/Datagrid/useDataGridMatricesBrushMode';

// INTERFACES
import { ICulture } from 'components/generics/Interface/ICulture';
import { IGroundType } from 'components/generics/Interface/Api/Response/GroundType/IGroundType';
import { IFormErrorsMatrices } from 'components/generics/Interface/Commons/IErrorForm';
import { IGroundTypeEffect } from 'components/generics/Interface/Api/Response/GroundTypeEffects/IGroundTypeEffect';
import { IErrorResponse } from 'components/generics/Interface/Commons/IErrorResponse';
import { IStackedRequestMatrice } from 'components/generics/Interface/RotationMatrices/IStackedRequestMatrice';

// THEMES
import './dataGridRotateEffects.style.scss';
import brushLogo from 'assets/images/brush.svg';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';

type DataGridGroundTypeEffectsParams = {
  cultures: ICulture[];
  refreshData: Function;
  ownerId: number;
  from: string;
  groundTypes: IGroundType[];
};

const DataGridGroundTypeEffects = ({
  cultures,
  refreshData,
  ownerId,
  from,
  groundTypes,
}: DataGridGroundTypeEffectsParams) => {
  const [rows, setRows] = useState<GridRowsProp | []>([]);
  const [columns, setColumns] = useState<IGroundType[] | []>([]);
  const [allowSubmitEffect, setAllowSubmitEffect] = useState<boolean>(false);
  const stackedRequestsRef = useRef<IStackedRequestMatrice[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [errorsEffectFormDefault, setErrorsEffectFormDefault] = useState<IFormErrorsMatrices>({
    formError: false,
    effect: {
      message: '',
      validator: [inputFloatValidatorRotateEffect],
    },
    cellErrors: {},
  });

  const [editedRows, setEditedRows] = useState<GridRowsProp | []>([]);

  const handleFullScreen: React.MouseEventHandler<SVGSVGElement> = () => {
    setFullScreen(!fullScreen);
  };

  const {
    toggleFilter,
    setToggleFilter,
    selectedFilters,
    setSelectedFilters,
    selectAllFilters,
    setSelectAllFilters,
  } = useFilteredRotationsLocalStorage(
    Number(ownerId),
    from,
    cultures,
    'groundTypeEffect',
    groundTypes
  );

  const { renderCell, onCellEditStop, rowModesModel, setRowModesModel, handleRowModesModelChange } =
    useDataGridMatricesCellBehaviour({
      stackedRequestsRef,
      errorsEffectFormDefault,
      setErrorsEffectFormDefault,
      validateDatagridMatrix,
    });

  const {
    modeBrush,
    setModeBrush,
    allowSubmitEffectBrush,
    setAllowSubmitEffectBrush,
    selectedBrushValue,
    setSelectedBrushValue,
    handleBrushEffect,
    handleClickValue,
  } = useDataGridMatricesBrushMode();

  useEffect(() => {
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  const processedRows = useMemo(() => {
    return cultures?.map((cultureInRow) => {
      let processedRow = {
        id: cultureInRow.id,
        cropToPlant: cultureInRow.name,
        groundTypeEffects: cultureInRow.groundTypeEffects,
      };

      return processedRow;
    });
  }, [cultures]);

  useEffect(() => {
    setIsLoading(true);
    const filteredRows = processedRows?.filter((culture) => {
      return selectedFilters?.rows?.[culture.id] !== false;
    });
    const filteredColumns = groundTypes?.filter((groundType) => {
      return selectedFilters?.columns?.[groundType.id] !== false;
    });
    if (
      filteredRows?.length !== processedRows?.length ||
      filteredColumns?.length !== groundTypes?.length
    ) {
      processedRows && setRows(filteredRows);
      processedRows && setColumns(filteredColumns);
    } else {
      processedRows && setRows(processedRows);
      processedRows && setColumns(groundTypes);
    }
    setIsLoading(false);
  }, [cultures, groundTypes]);

  const renderEditBrushAutomaticEffectCell = (params: GridRenderCellParams) => {
    return (
      <CustomEditBrushAutomaticEffectCell selectedBrushValue={selectedBrushValue} params={params} />
    );
  };

  const renderEditEffectCell = (params: GridRenderCellParams) => {
    return (
      <CustomEffectEditCell
        params={params}
        errorsEffectFormDefault={errorsEffectFormDefault}
        setRowModesModel={setRowModesModel}
      />
    );
  };

  const { handleOnChangeFilter, renderFilterMenuItems, handleToggleFilter, isIndeterminate } =
    useDatagridMatricesFilter({
      selectedFilters,
      setSelectedFilters,
      selectAllFilters,
      setSelectAllFilters,
      setToggleFilter,
    });

  const processColumns: GridColDef[] =
    columns?.map((groundType) => {
      return {
        field: groundType.id.toString(),
        id: groundType.id,
        headerName: groundType.name,
        headerClassName: 'cropEffect-header',
        editable: true,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        type: 'number',
        minWidth: 80,
        cellClassName: 'viewModeCell',
        renderCell: renderCell,
        renderEditCell:
          modeBrush && selectedBrushValue !== null
            ? renderEditBrushAutomaticEffectCell
            : renderEditEffectCell,
        valueGetter: (params: GridRenderCellParams) => {
          return params?.row?.groundTypeEffects?.find(
            (groundTypeEffect: IGroundTypeEffect) =>
              groundTypeEffect?.groundType?.id === groundType?.id
          )?.effect;
        },
        valueParser: (value: string) => {
          return checkThreeValueAndDecimalRegexTwoDigitsDatagrid(value);
        },
      };
    }) ?? [];

  const cropToPlantColumn: GridColDef = {
    field: 'cropToPlant',
    headerName: 'Culture à implanter',
    headerClassName: 'cropToPlant-header',
    renderHeader: CustomCropToPlantHeader,
    renderCell: customCropToPlantCell,
    cellClassName: 'cropToPlant-cell',
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 140,
  };

  const noColumns: GridColDef = {
    field: 'noColumns',
    headerName: '',
    headerClassName: 'cropEffect-header',
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.id === rows[0]?.id) {
        return <div className="noColumns">Aucune colonne à afficher</div>;
      }
      return null;
    },
    cellClassName: 'viewModeCell-noColumns',
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 140,
  };

  const processedColumns: GridColDef[] = [
    cropToPlantColumn,
    ...(processColumns && processColumns?.length > 0 ? processColumns : [noColumns]),
  ];

  const originalRowsCount = processedRows?.length;
  const countFilteredRows = originalRowsCount - rows.length;

  const countFilteredColumns = groundTypes?.length - columns?.length;

  const processRowUpdate = useCallback(
    async (updatedRow: GridRowModel, previousRow: GridRowModel) => {
      errorsEffectFormDefault.formError = false;
      const toUpdate: { [key: string]: number } | undefined = getNewKeyBetweenObjects(
        previousRow,
        updatedRow
      );

      if (!toUpdate) {
        return previousRow;
      }

      const previousCrop = Number(Object.keys(toUpdate)[0]);
      const isEffectUpdated =
        previousRow.groundTypeEffects.find(
          (groundTypeEffect: IGroundTypeEffect) => groundTypeEffect?.groundType?.id === previousCrop
        ).effect !== toUpdate[previousCrop];

      const isEffectSameAsOriginalEffect =
        cultures
          .find((culture) => culture?.id === updatedRow?.id)
          ?.groundTypeEffects?.find(
            (groundTypeEffect) => groundTypeEffect?.groundType?.id === previousCrop
          )?.effect === toUpdate[previousCrop];

      if (isEffectUpdated) {
        const formatedToUpdateObject = { ...toUpdate };
        const formatedToUpdateObjectDescriptor = Object.getOwnPropertyDescriptor(
          formatedToUpdateObject,
          previousCrop
        );

        if (formatedToUpdateObjectDescriptor === undefined) {
          return previousRow;
        }

        Object.defineProperty(formatedToUpdateObject, 'effect', formatedToUpdateObjectDescriptor);
        delete formatedToUpdateObject[previousCrop];

        setErrorsEffectFormDefault({
          ...validateDatagridMatrix(
            formatedToUpdateObject,
            errorsEffectFormDefault,
            updatedRow.id,
            previousCrop
          ),
        });

        updatedRow = {
          ...updatedRow,
          groundTypeEffects: updatedRow.groundTypeEffects.map(
            (groundTypeEffect: IGroundTypeEffect) => {
              return groundTypeEffect?.groundType?.id === previousCrop
                ? {
                    ...groundTypeEffect,
                    effect: toUpdate[previousCrop],
                  }
                : groundTypeEffect;
            }
          ),
        };
        delete updatedRow[previousCrop];

        const updatedEffect = updatedRow.groundTypeEffects.find(
          (groundTypeEffect: IGroundTypeEffect) => groundTypeEffect.groundType.id === previousCrop
        );

        let existInStackedRequest = false;
        stackedRequestsRef.current.forEach((request: IStackedRequestMatrice) => {
          if (request?.uriParams?.rotateEffectId === updatedEffect?.id) {
            existInStackedRequest = true;
          }
        });

        await handleRowUpdateValidation(updatedRow, errorsEffectFormDefault);

        if (!existInStackedRequest) {
          stackedRequestsRef.current.push({
            uriParams: {
              ownerId: ownerId,
              rotateEffectId: updatedEffect?.id,
            },
            body: {
              id: updatedEffect?.id,
              from: updatedEffect?.from,
              effect: updatedEffect?.effect,
            },
            cellInformations: {
              rowId: updatedRow.id,
              columnId: previousCrop,
              rotateEffectId: updatedEffect?.id,
              effect: updatedEffect?.effect,
            },
          });
        } else if (!isEffectSameAsOriginalEffect) {
          stackedRequestsRef.current = stackedRequestsRef.current.map((request) => {
            if (request.uriParams.rotateEffectId === updatedEffect?.id) {
              return {
                ...request,
                body: {
                  ...request.body,
                  effect: updatedEffect?.effect,
                },
                cellInformations: {
                  ...request.cellInformations,
                  effect: updatedEffect?.effect,
                },
              };
            } else {
              return request;
            }
          });
        } else {
          let tempStackedRequests: IStackedRequestMatrice[] = [];
          stackedRequestsRef.current.forEach((request) => {
            if (request.cellInformations.rotateEffectId !== updatedEffect?.id) {
              tempStackedRequests.push(request);
            }
          });
          stackedRequestsRef.current = tempStackedRequests;
        }

        setAllowSubmitEffect(stackedRequestsRef?.current?.length > 0);
        if (modeBrush) {
          setAllowSubmitEffectBrush(true);
        }

        setEditedRows((prev) => ({
          ...prev,
          [updatedRow.id]: updatedRow,
        }));

        return updatedRow;
      }

      return previousRow;
    },
    [cultures, stackedRequestsRef, rows, editedRows]
  );

  const callAPI = async () => {
    setIsLoading(true);
    if (Object.keys(errorsEffectFormDefault?.cellErrors)?.length !== 0) {
      toast.warning(
        "Des effets de type de sol contiennent des valeurs invalides, ils n'ont pas pas été enregistrés."
      );
    }

    const requestBody = stackedRequestsRef.current.map((request) => request.body);

    try {
      await updateGroundTypeEffect(from, ownerId, requestBody);
      setAllowSubmitEffect(false);
      setAllowSubmitEffectBrush(false);
      setModeBrush(false);
      setSelectedBrushValue(null);
      setEditedRows([]);
    } catch (error) {
      const apiError = error as IErrorResponse;
      if (apiError) {
        toast.error(
          apiError?.response?.data?.message ??
            "Une erreur est survenue : impossible de mettre à jour l'effet de type de sol."
        );
      }
    }
    stackedRequestsRef.current = [];
    refreshData();

    toast.success('Les effets de types de sol ont été mis à jour.');
  };

  useEffect(() => {
    if (processedRows && processedColumns && !isLoading) {
      const filteredRows = processedRows?.filter((row) => selectedFilters?.rows?.[row.id]);
      const columnsToFilter = groundTypes?.filter(
        (groundType) => selectedFilters?.columns?.[groundType.id]
      );
      const updatedFilteredRows = filteredRows.map((row) => {
        if (editedRows[row.id]) {
          return { ...row, ...editedRows[row.id] };
        }
        return row;
      });
      setRows(updatedFilteredRows || []);
      setColumns(columnsToFilter || []);
    }
  }, [selectedFilters, editedRows]);

  const filterMenuItems = cultures?.map((culture) => {
    if (selectedFilters?.rows !== undefined) {
      return renderFilterMenuItems(culture, selectedFilters?.rows);
    } else {
      return [];
    }
  });
  const filterMenuColumnsItems = groundTypes?.map((groundType) => {
    if (selectedFilters?.columns !== undefined) {
      return renderFilterMenuItems(groundType, selectedFilters?.columns);
    } else {
      return [];
    }
  });

  return (
    cultures && (
      <div className="section">
        <div className="main_container">
          <div className="override">
            <h1 className="title_section title_section_culture title_rotate_effects">
              Matrice de type de sol
            </h1>
          </div>
          <div
            className={`datagrid_container rotate_effects_container ${
              modeBrush ? 'modeBrush' : ''
            }`}
          >
            <div className="contain_tab_rotation">
              <ThemeProvider theme={customButtonTheme}>
                <Button disabled={!allowSubmitEffect} onClick={callAPI}>
                  Enregistrer les modifications
                </Button>
                <div className="contain_brush">
                  <Button disabled={!allowSubmitEffectBrush} onClick={handleBrushEffect}>
                    Terminer les modifications
                    <span>
                      <img src={brushLogo} alt="Logo pinceau" className="brush_logo" />
                    </span>
                  </Button>
                </div>
              </ThemeProvider>
            </div>
            <div className={`${fullScreen ? 'fullscreen' : ''}`}>
              <div
                className={`${
                  fullScreen && toggleFilter
                    ? 'contain_brush_effect_fullscreen contain_brush_effect'
                    : 'contain_brush_effect'
                }`}
              >
                <ThemeProvider theme={themeRotateEffectsFilter}>
                  <div className="filter_container_rotate_effects">
                    <div className="button_filters_effects" onClick={handleToggleFilter}>
                      <span>{toggleFilter ? '' : 'Afficher les filtres'}</span>
                      <Settings className="settings-icon" />
                    </div>
                    {toggleFilter && (
                      <>
                        <div>
                          <FormControl className="formControl">
                            <InputLabel shrink={false} id="filterLabel" className="input-label">
                              Filtrer les lignes
                            </InputLabel>
                            <Select
                              labelId="filterLabel"
                              id="filter"
                              value={
                                selectedFilters.rows &&
                                Object.keys(selectedFilters.rows).filter(
                                  (id: string) => selectedFilters?.rows?.[Number(id)]
                                )
                              }
                              onChange={(event) =>
                                handleOnChangeFilter(
                                  typeof event.target.value === 'string'
                                    ? [event.target.value]
                                    : event.target.value,
                                  'rows',
                                  cultures
                                )
                              }
                              multiple
                              renderValue={() => ''}
                              MenuProps={{ autoFocus: false }}
                            >
                              <MenuItem value="all">
                                <Checkbox
                                  indeterminate={isIndeterminate('rows')}
                                  checked={selectAllFilters?.rows}
                                />
                                <ListItemText primary="Tout sélectionner" />
                              </MenuItem>
                              {filterMenuItems}
                            </Select>
                            <StyledBadge
                              badgeContent={countFilteredRows}
                              color="primary"
                            ></StyledBadge>
                          </FormControl>
                        </div>
                        <div>
                          <FormControl className="formControl">
                            <InputLabel shrink={false} id="filterLabel" className="input-label">
                              Filtrer les colonnes
                            </InputLabel>
                            <Select
                              labelId="filterLabel"
                              id="filter"
                              value={
                                selectedFilters.columns &&
                                Object.keys(selectedFilters.columns).filter(
                                  (id: string) => selectedFilters?.columns?.[Number(id)]
                                )
                              }
                              onChange={(event) =>
                                handleOnChangeFilter(
                                  typeof event.target.value === 'string'
                                    ? [event.target.value]
                                    : event.target.value,
                                  'columns',
                                  groundTypes
                                )
                              }
                              multiple
                              renderValue={() => ''}
                              MenuProps={{ autoFocus: false }}
                            >
                              <MenuItem value="all">
                                <Checkbox
                                  indeterminate={isIndeterminate('columns')}
                                  checked={selectAllFilters?.columns}
                                />
                                <ListItemText primary="Tout sélectionner" />
                              </MenuItem>
                              {filterMenuColumnsItems}
                            </Select>
                            <StyledBadge
                              badgeContent={countFilteredColumns}
                              color="primary"
                            ></StyledBadge>
                          </FormControl>
                        </div>
                      </>
                    )}
                  </div>
                </ThemeProvider>
                <div
                  className={`${
                    fullScreen ? (toggleFilter ? 'fullscreen-div' : '') : 'not-fullscreen'
                  }`}
                ></div>
                <div>
                  <ThemeProvider theme={themeRotateEffectsPalette}>
                    <div className="list_effect">
                      {brushValueList &&
                        brushValueList?.map((brushValue, index) => {
                          return (
                            <Badge
                              key={brushValue.number}
                              badgeContent={'✓'}
                              color="primary"
                              invisible={selectedBrushValue !== brushValue.number.toString()}
                            >
                              <span
                                className={`colorSpan ${
                                  Number(selectedBrushValue) === brushValue.number
                                    ? 'selectedBrushValue'
                                    : ''
                                }`}
                                onClick={handleClickValue}
                                style={{
                                  backgroundColor: `color-mix(in srgb, ${brushValue.color} 100%, white)`,
                                  color: index >= 3 && index < 6 ? 'black' : 'white',
                                  cursor: modeBrush ? '' : 'pointer',
                                }}
                              >
                                {brushValue.number}
                              </span>
                            </Badge>
                          );
                        })}
                    </div>
                  </ThemeProvider>
                </div>

                <div className="contain_switch_effect">
                  {fullScreen && (
                    <div
                      className={`contain_brush ${toggleFilter ? 'contain_brush_fullscreen' : ''}`}
                    >
                      <ThemeProvider theme={customButtonTheme}>
                        <Button disabled={!allowSubmitEffectBrush} onClick={handleBrushEffect}>
                          Terminer les modifications
                          <span>
                            <img src={brushLogo} alt="Logo pinceau" className="brush_logo" />
                          </span>
                        </Button>
                      </ThemeProvider>
                    </div>
                  )}
                  {fullScreen ? (
                    <ZoomInMapIcon onClick={handleFullScreen} />
                  ) : (
                    <ZoomOutMapIcon onClick={handleFullScreen} />
                  )}
                </div>
              </div>
              <CustomDataGridResult
                rows={rows}
                fromRotateEffects
                columns={processedColumns}
                processRowUpdate={processRowUpdate}
                onCellEditStop={onCellEditStop}
                handleRowModesModelChange={handleRowModesModelChange}
                rowModesModel={rowModesModel}
                setRowModesModel={setRowModesModel}
                fullSize={true}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DataGridGroundTypeEffects;
